import { Grid, Link, Typography } from "@mui/material";

export function ClosedBetaDialog() { 

  return (
    <Grid container justifyContent='center' sx={{ p: 3 }}>
      <Typography variant='h3' textAlign='center'>Albumize is in closed beta</Typography>
      <Typography sx={{mt:2}}>If you would like to join the beta
        or you would just like to know more, 
        let me know at <Link sx={{color:'#dddddd'}} href="mailto:help@albumize.click">help@albumize.click</Link></Typography>
    </Grid>
  );
}