import React from 'react';
import { Avatar, Button, CssBaseline, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import ExplainerCard from './ExplainerCard';
import { ClosedBetaDialog } from './ClosedBetaDialog';
import Privacy from './PrivacyDialog';
import CloseIcon from '@mui/icons-material/Close';
import TermsOfServiceDialog from './TermsOfServiceDialog';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#A6213F' },
    secondary: { main: '#600761' },
  },
});

function App() {

  const [closedBetaDialogOpen, setClosedBetaDialogOpen] = React.useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = React.useState(false);
  const [tosDialogOpen, setTosDialogOpen] = React.useState(false);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Grid container spacing={2} sx={{ minWidth: '375px' }}>
        <Grid container justifyContent='center' sx={{ p: 2, pt: 3 }}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems='center'>
            <Avatar alt='Albumize' src='/logo192.png' sx={{ width: 64, height: 64 }} />
            <Typography variant='h3' textAlign='center' fontWeight='bold'>Albumize</Typography>
            <Typography variant='h3' textAlign='center' fontWeight='bold'>for</Typography>
            <Avatar alt='Albumize' src='/spotifywordmark.svg'
              sx={{ width: 116, height: 34 }}
              variant='square' />
          </Stack>
        </Grid>
        <Grid container justifyContent='center' sx={{ p: 1 }}>
          <Button variant='contained' color='primary'
            sx={{ borderRadius: 5, fontSize: '2rem', fontWeight: 'bold', textTransform: 'none' }}
            href='https://app.albumize.click'
          >Get started</Button>
        </Grid>
        <Grid container justifyContent='center' sx={{ p: 2 }}>
          <Typography variant='body1' textAlign='center' fontWeight='bold'>
            Albumize is a way to manage your Spotify listening by album
          </Typography>
        </Grid>
        <Grid container justifyContent='center' sx={{ pl: 3, pr: 3, pt: 2 }} spacing={5}>
          <ExplainerCard
            title='Clean view'
            description='Albums from Spotify are presented in a simple, easy-to-read table'
            image='/screenshots/ss1.png' />
          <ExplainerCard
            title='View albums and playlists'
            description='View albums from your library and in playlists: organise albums with tags.'
            image='/screenshots/ss2.png' />
          <ExplainerCard
            title='Sort'
            description='Search and sort albums by release date, duration, popularity, and more'
            image='/screenshots/ss3.png' />
          <ExplainerCard
            title='Filter'
            description='Filter out compilations and singles or EPs'
            image='/screenshots/ss4.png' />
        </Grid>
        <Grid container justifyContent='center' sx={{ p: 2 }}>
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='body2'>©️ Gareth Cronin {new Date().getFullYear()}</Typography>
            <Link href='#' onClick={() => setPrivacyDialogOpen(true)}>Privacy policy</Link>
            <Link href='#' onClick={() => setTosDialogOpen(true)}>Terms of service</Link>
          </Stack>
        </Grid>
      </Grid>
      
      <Dialog open={closedBetaDialogOpen} onClose={() => setClosedBetaDialogOpen(false)}>
        <ClosedBetaDialog />
      </Dialog>

      <Dialog open={privacyDialogOpen} onClose={() => setPrivacyDialogOpen(false)}>
        <DialogTitle>
          <Typography variant='h4' fontWeight={700}>Privacy policy</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setPrivacyDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Privacy />
        </DialogContent>
      </Dialog>

      <Dialog open={tosDialogOpen} onClose={() => setTosDialogOpen(false)} fullWidth>
        <DialogTitle>
          <Typography variant='h4' fontWeight={700}>Terms of service</Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTosDialogOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <TermsOfServiceDialog />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;
