import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const TermsOfServiceDialog = () => {
  return <Box>
    <Stack>
      <Typography variant='body1'>AGREEMENT TO TERMS</Typography>

      <Typography variant='body1'>These Terms of Service constitute a legally binding agreement made between you, whether personally or on behalf
        of an entity (&quot;you) and Albumize (&quot;we,&quot; &quot;us&quot; or &quot;our&quot;), concerning your access to and use of the Google
        Calendar add-on and website as well as any other media form, media channel, mobile website or mobile application
        related, linked, or otherwise connected thereto (collectively, the &quot;Site&quot;). </Typography>

      <Typography variant='body1'>You agree that by accessing the Site, you have read, understood, and agree to be bound by all of these Terms of
        Service. If you do not agree with all of these Terms of Service, then you are expressly prohibited from using
        the Site and you must discontinue use immediately.</Typography>

      <Typography variant='body1'>Supplemental Terms of Service or documents that may be posted on the Site from time to time are hereby
        expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or
        modifications to these Terms of Service at any time and for any reason. </Typography>
      <Typography variant='body1'>We will alert you about any changes by updating the &quot;Last updated&quot; date of these Terms of Service, and you
        waive any right to receive specific notice of each such change. </Typography>
      <Typography variant='body1'>It is your responsibility to periodically review these Terms of Service to stay informed of updates. You will
        be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised
        Terms of Service by your continued use of the Site after the date such revised Terms of Service are posted. </Typography>
      <Typography variant='body1'>The information provided on the Site is not intended for distribution to or use by any person or entity in any
        jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
        subject us to any registration requirement within such jurisdiction or country. </Typography>
      <Typography variant='body1'>Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and
        are solely responsible for compliance with local laws, if and to the extent local laws are applicable. </Typography>
      <Typography variant='body1'>All users who are minors in the jurisdiction in which they reside (generally under the age of 18) must have the
        permission of, and be directly supervised by, their parent or guardian to use the Site. If you are a minor, you
        must have your parent or guardian read and agree to these Terms of Service prior to you using the Site. </Typography>
      <Typography variant='body1'>INTELLECTUAL PROPERTY RIGHTS</Typography>
      <Typography variant='body1'>Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the
        &quot;Content&quot;) and the trademarks, service marks, and logos contained therein (the &quot;Marks&quot;) are owned or controlled
        by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual
        property rights and unfair competition laws of the United States, foreign jurisdictions, and international
        conventions. </Typography>
      <Typography variant='body1'>The Content and the Marks are provided on the Site &quot;AS IS&quot; for your information and personal use only. Except
        as expressly provided in these Terms of Service, no part of the Site and no Content or Marks may be copied,
        reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted,
        distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express
        prior written permission.</Typography>
      <Typography variant='body1'>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site
        and to download or print a copy of any portion of the Content to which you have properly gained access solely
        for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site,
        the Content and the Marks.</Typography>
      <Typography variant='body1'>USER REPRESENTATIONS</Typography>
      <Typography variant='body1'>By using the Site, you represent and warrant that: </Typography>
      <Typography variant='body1'>(1) all registration information you submit will be true, accurate, current, and complete; (2) you will
        maintain the accuracy of such information and promptly update such registration information as necessary;</Typography>
      <Typography variant='body1'>(3) you have the legal capacity and you agree to comply with these Terms of Service; </Typography>
      <Typography variant='body1'>(4) you are not under the age of 13;</Typography>
      <Typography variant='body1'>(5) not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission
        to use the Site; </Typography>
      <Typography variant='body1'>(6) you will not access the Site through automated or non-human means, whether through a bot, script, or
        otherwise; </Typography>
      <Typography variant='body1'>(7) you will not use the Site for any illegal or unauthorized purpose; </Typography>
      <Typography variant='body1'>(8) your use of the Site will not violate any applicable law or regulation.</Typography>
      <Typography variant='body1'>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
        suspend or terminate your account and refuse any and all current or future use of the Site (or any portion
        thereof). </Typography>
      <Typography variant='body1'>USER REGISTRATION</Typography>
      <Typography variant='body1'>You may be required to register with the Site. You agree to keep your password confidential and will be
        responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a
        username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or
        otherwise objectionable.</Typography>
      <Typography variant='body1'>PROHIBITED ACTIVITIES</Typography>
      <Typography variant='body1'>You may not access or use the Site for any purpose other than that for which we make the Site available. The
        Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or
        approved by us. </Typography>
      <Typography variant='body1'>As a user of the Site, you agree not to:</Typography>
      <Typography variant='body1'>systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a
        collection, compilation, database, or directory without written permission from us.</Typography>
      <Typography variant='body1'>make any unauthorized use of the Site, including collecting usernames and/or email addresses of users by
        electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated
        means or under false pretenses.</Typography>
      <Typography variant='body1'>use a buying agent or purchasing agent to make purchases on the Site.</Typography>
      <Typography variant='body1'>use the Site to advertise or offer to sell goods and services.</Typography>
      <Typography variant='body1'>circumvent, disable, or otherwise interfere with security-related features of the Site, including features that
        prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the
        Content contained therein.</Typography>
      <Typography variant='body1'>engage in unauthorized framing of or linking to the Site.</Typography>
      <Typography variant='body1'>trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information
        such as user passwords;</Typography>
      <Typography variant='body1'>make improper use of our support services or submit false reports of abuse or misconduct.</Typography>
      <Typography variant='body1'>engage in any automated use of the system, such as using scripts to send comments or messages, or using any
        data mining, robots, or similar data gathering and extraction tools.</Typography>
      <Typography variant='body1'>interfere with, disrupt, or create an undue burden on the Site or the networks or services connected to the
        Site.</Typography>
      <Typography variant='body1'>attempt to impersonate another user or person or use the username of another user.</Typography>
      <Typography variant='body1'>sell or otherwise transfer your profile.</Typography>
      <Typography variant='body1'>use any information obtained from the Site in order to harass, abuse, or harm another person.</Typography>
      <Typography variant='body1'>use the Site as part of any effort to compete with us or otherwise use the Site and/or the Content for any
        revenue-generating endeavor or commercial enterprise.</Typography>
      <Typography variant='body1'>decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a
        part of the Site.</Typography>
      <Typography variant='body1'>attempt to bypass any measures of the Site designed to prevent or restrict access to the Site, or any portion
        of the Site.</Typography>
      <Typography variant='body1'>harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the
        Site to you.</Typography>
      <Typography variant='body1'>delete the copyright or other proprietary rights notice from any Content.</Typography>
      <Typography variant='body1'>copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
      </Typography>
      <Typography variant='body1'>upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including
        excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any
        party’s uninterrupted use and enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with
        the use, features, functions, operation, or maintenance of the Site.</Typography>
      <Typography variant='body1'>upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
        information collection or transmission mechanism, including without limitation, clear graphics interchange
        formats (&quot;gifs&quot;), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as &quot;spyware&quot; or
        &quot;passive collection mechanisms&quot; or &quot;pcms&quot;).</Typography>
      <Typography variant='body1'>except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
        distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or
        offline reader that accesses the Site, or using or launching any unauthorized script or other software.</Typography>
      <Typography variant='body1'>disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</Typography>
      <Typography variant='body1'>use the Site in a manner inconsistent with any applicable laws or regulations.</Typography>
      <Typography variant='body1'>SITE MANAGEMENT</Typography>
      <Typography variant='body1'>We reserve the right, but not the obligation, to: </Typography>
      <Typography variant='body1'>(1) monitor the Site for violations of these Terms of Service; </Typography>
      <Typography variant='body1'>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms
        of Service, including without limitation, reporting such user to law enforcement authorities; </Typography>
      <Typography variant='body1'>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
        disable (to the extent technologically feasible) any of your Contributions or any portion thereof; </Typography>
      <Typography variant='body1'>(4) in our sole discretion and without limitation, notice, or liability, to remove from the Site or otherwise
        disable all files and content that are excessive in size or are in any way burdensome to our systems; </Typography>
      <Typography variant='body1'>(5) otherwise manage the Site in a manner designed to protect our rights and property and to facilitate the
        proper functioning of the Site.</Typography>
      <Typography variant='body1'>PRIVACY POLICY</Typography>
      <Typography variant='body1'>We care about data privacy and security. Please review our Privacy Policy at
        https://albumize.click. By using the Site, you agree to be bound by our Privacy Policy,
        which is incorporated into these Terms of Service. </Typography>
      <Typography variant='body1'>If you access the Site from the European Union, Asia, or any other region of the world with laws or other
        requirements governing personal data collection, use, or disclosure that differ from applicable laws in the
        United States, then through your continued use of the Site, you are transferring your data to the United States,
        and you expressly consent to have your data transferred to and processed in the United States. </Typography>
      <Typography variant='body1'>Further, we do not knowingly accept, request, or solicit information from children or knowingly market to
        children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual
        knowledge that anyone under the age of 13 has provided personal information to us without the requisite and
        verifiable parental consent, we will delete that information from the Site as quickly as is reasonably
        practical.</Typography>
      <Typography variant='body1'>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY</Typography>
      <Typography variant='body1'>Notifications</Typography>
      <Typography variant='body1'>We respect the intellectual property rights of others. If you believe that any material available on or through
        the Site infringes upon any copyright you own or control, please immediately notify our Designated Copyright
        Agent using the contact information provided below (a &quot;Notification&quot;). </Typography>
      <Typography variant='body1'>A copy of your Notification will be sent to the person who posted or stored the material addressed in the
        Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make
        material misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to
        by the Site infringes your copyright, you should consider first contacting an attorney.</Typography>
      <Typography variant='body1'>All Notifications should meet the requirements of DMCA 17 U.S.C. § 512(c)(3) and include the following
        information: </Typography>
      <Typography variant='body1'>(1) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
        right that is allegedly infringed; </Typography>
      <Typography variant='body1'>(2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on
        the Site are covered by the Notification, a representative list of such works on the Site; </Typography>
      <Typography variant='body1'>(3) identification of the material that is claimed to be infringing or to be the subject of infringing activity
        and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit
        us to locate the material; </Typography>
      <Typography variant='body1'>(4) information reasonably sufficient to permit us to contact the complaining party, such as an address,
        telephone number, and, if available, an email address at which the complaining party may be contacted; </Typography>
      <Typography variant='body1'>(5) a statement that the complaining party has a good faith belief that use of the material in the manner
        complained of is not authorized by the copyright owner, its agent, or the law; </Typography>
      <Typography variant='body1'>(6) a statement that the information in the notification is accurate, and under penalty of perjury, that the
        complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed
        upon.</Typography>
      <Typography variant='body1'>Counter Notification</Typography>
      <Typography variant='body1'>If you believe your own copyrighted material has been removed from the Site as a result of a mistake or
        misidentification, you may submit a written counter notification to Albumize using the contact information
        provided below (a &quot;Counter Notification&quot;). </Typography>
      <Typography variant='body1'>To be an effective Counter Notification under the DMCA, your Counter Notification must include substantially
        the following: </Typography>
      <Typography variant='body1'>(1) identification of the material that has been removed or disabled and the location at which the material
        appeared before it was removed or disabled; </Typography>
      <Typography variant='body1'>(2) a statement that you consent to the jurisdiction of the Federal District Court in which your address is
        located, or if your address is outside the United States, for any judicial district in which we are located;
      </Typography>
      <Typography variant='body1'>(3) a statement that you will accept service of process from the party that filed the Notification or the
        party&apos;s agent; </Typography>
      <Typography variant='body1'>(4) your name, address, and telephone number; </Typography>
      <Typography variant='body1'>(5) a statement under penalty of perjury that you have a good faith belief that the material in question was
        removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled;
      </Typography>
      <Typography variant='body1'>(6) your physical or electronic signature.</Typography>
      <Typography variant='body1'>If you send us a valid, written Counter Notification meeting the requirements described above, we will restore
        your removed or disabled material, unless we first receive notice from the party filing the Notification
        informing us that such party has filed a court action to restrain you from engaging in infringing activity
        related to the material in question.</Typography>
      <Typography variant='body1'>Please note that if you materially misrepresent that the disabled or removed content was removed by mistake or
        misidentification, you may be liable for damages, including costs and attorney&apos;s fees. Filing a false Counter
        Notification constitutes perjury.</Typography>
      <Typography variant='body1'>Designated Copyright Agent</Typography>
      <Typography variant='body1'>Gareth Cronin</Typography>
      <Typography variant='body1'>Attn: Copyright Agent</Typography>
      <Typography variant='body1'>help@albumize.click</Typography>
      <Typography variant='body1'>COPYRIGHT INFRINGEMENTS</Typography>
      <Typography variant='body1'>We respect the intellectual property rights of others. If you believe that any material available on or through
        the Site infringes upon any copyright you own or control, please immediately notify us using the contact
        information provided below (a &quot;Notification&quot;). A copy of your Notification will be sent to the person who posted
        or stored the material addressed in the Notification. </Typography>
      <Typography variant='body1'>Please be advised that pursuant to federal law you may be held liable for damages if you make material
        misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the
        Site infringes your copyright, you should consider first contacting an attorney.</Typography>
      <Typography variant='body1'>TERM AND TERMINATION</Typography>
      <Typography variant='body1'>These Terms of Service shall remain in full force and effect while you use the Site. WITHOUT LIMITING ANY OTHER
        PROVISION OF THESE TERMS OF SERVICE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
        REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT
        CONTAINED IN THESE TERMS OF SERVICE OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
        PARTICIPATION IN THE SITE. </Typography>
      <Typography variant='body1'>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new
        account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on
        behalf of the third party. </Typography>
      <Typography variant='body1'>In addition to terminating or suspending your account, we reserve the right to take appropriate legal action,
        including without limitation pursuing civil, criminal, and injunctive redress.</Typography>
      <Typography variant='body1'>MODIFICATIONS AND INTERRUPTIONS </Typography>
      <Typography variant='body1'>We reserve the right to change, modify, or remove the contents of the Site at any time or for any reason at our
        sole discretion without notice. However, we have no obligation to update any information on our Site. We also
        reserve the right to modify or discontinue all or part of the Site without notice at any time. </Typography>
      <Typography variant='body1'>We will not be liable to you or any third party for any modification, price change, suspension, or
        discontinuance of the Site. </Typography>
      <Typography variant='body1'>We cannot guarantee the Site will be available at all times. We may experience hardware, software, or other
        problems or need to perform maintenance related to the Site, resulting in interruptions, delays, or errors. </Typography>
      <Typography variant='body1'>We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Site at any time
        or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
        inconvenience caused by your inability to access or use the Site during any downtime or discontinuance of the
        Site. </Typography>
      <Typography variant='body1'>Nothing in these Terms of Service will be construed to obligate us to maintain and support the Site or to
        supply any corrections, updates, or releases in connection therewith.</Typography>
      <Typography variant='body1'>CORRECTIONS</Typography>
      <Typography variant='body1'>There may be information on the Site that contains typographical errors, inaccuracies, or omissions that may
        relate to the Site, including descriptions, pricing, availability, and various other information. We reserve the
        right to correct any errors, inaccuracies, or omissions and to change or update the information on the Site at
        any time, without prior notice.</Typography>
      <Typography variant='body1'>DISCLAIMER</Typography>
      <Typography variant='body1'>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES
        WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
        IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
        REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
        TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
        CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
        ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO
        OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
        ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
        MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
        SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR
        MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
        RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
      </Typography>
      <Typography variant='body1'>AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
        JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</Typography>
      <Typography variant='body1'>LIMITATIONS OF LIABILITY</Typography>
      <Typography variant='body1'>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
        INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
        REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES. </Typography>
      <Typography variant='body1'>USER DATA</Typography>
      <Typography variant='body1'>We will maintain certain data that you transmit to the Site for the purpose of managing the Site, as well as
        data relating to your use of the Site. Although we perform regular routine backups of data, you are solely
        responsible for all data that you transmit or that relates to any activity you have undertaken using the Site.
      </Typography>
      <Typography variant='body1'>You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby
        waive any right of action against us arising from any such loss or corruption of such data.</Typography>
      <Typography variant='body1'>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</Typography>
      <Typography variant='body1'>Visiting the Site, sending us emails, and completing online forms constitute electronic communications. You
        consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
        communications we provide to you electronically, via email and on the Site, satisfy any legal requirement that
        such communication be in writing. </Typography>
      <Typography variant='body1'>YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
        DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. </Typography>
      <Typography variant='body1'>You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws
        in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or
        to payments or the granting of credits by any means other than electronic means. </Typography>
      <Typography variant='body1'>MISCELLANEOUS</Typography>
      <Typography variant='body1'>These Terms of Service and any policies or operating rules posted by us on the Site constitute the entire
        agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of
        these Terms of Service shall not operate as a waiver of such right or provision. </Typography>
      <Typography variant='body1'>These Terms of Service operate to the fullest extent permissible by law. We may assign any or all of our rights
        and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or
        failure to act caused by any cause beyond our reasonable control. </Typography>
      <Typography variant='body1'>If any provision or part of a provision of these Terms of Service is determined to be unlawful, void, or
        unenforceable, that provision or part of the provision is deemed severable from these Terms of Service and does
        not affect the validity and enforceability of any remaining provisions. </Typography>
      <Typography variant='body1'>There is no joint venture, partnership, employment or agency relationship created between you and us as a
        result of these Terms of Service or use of the Site. You agree that these Terms of Service will not be construed
        against us by virtue of having drafted them. </Typography>
      <Typography variant='body1'>You hereby waive any and all defenses you may have based on the electronic form of these Terms of Service and
        the lack of signing by the parties hereto to execute these Terms of Service.</Typography>
      <Typography variant='body1'>CONTACT US </Typography>
      <Typography variant='body1'>In order to resolve a complaint regarding the Site or to receive further information regarding use of the Site,
        please contact us at: </Typography>
      <Typography variant='body1'>help@albumize.click</Typography>
    </Stack>
  </Box>;
}

export default TermsOfServiceDialog;