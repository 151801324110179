import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';

const Privacy = () => {
  return <Box>
    <Stack>
      <Typography variant="h6">Introduction</Typography>
      <Typography variant="body1">
        Privacy is important to Albumize, and the policy contained here explains how I handle your information and data
        when
        you use it.

        Please read the policy carefully and contact me with any questions or concerns about our privacy practices.
      </Typography>
      <Typography variant="h6">Who are we?</Typography>
      <Typography variant="body1">
        I (Gareth Cronin) am a sole trader, providing Albumize as a tool for people to listen to Spotify.
      </Typography>
      <Typography variant="h6">What information do we collect?</Typography>
      <Typography variant="body1">
        <ul>
          <li>I do not collect or store any other information - e.g. your name or email address is not stored</li>
          <li>I only store the preferences you provide for how to manage your music and caches of playlists</li>
        </ul>
      </Typography>

      <Typography variant="h6">How do we secure your data?</Typography>
      <Typography variant="body1">
        No data is stored other than your preferences.
        These are stored securely on Google's Firebase platform.
        You can revoke access at any time by removing the add-on from your Spotify account.
      </Typography>

      <Typography variant="h6">How to contact us?</Typography>
      <Typography variant="body1">
        You can email me on <Link color='#ddddff' href="mailto:help@albumize.click">help@albumize.click</Link>
      </Typography>

      <Typography variant="h6">Use of cookies and other technologies</Typography>
      <Typography variant="body1">
        Albumize does not use any cookies or tracking technology.
      </Typography>
    </Stack>
  </Box>;
}

export default Privacy;