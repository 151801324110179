import { Grid, Paper, Typography } from '@mui/material';
import React from 'react';

const ExplainerCard = ({ title, description, image }) => {
  return <Grid item xs={12} sm={6} md={3} >
    <Paper elevation={3} sx={{ p: 2, borderRadius: 5 }}>
      <Typography variant='body1' textAlign='justify' sx={{ pb: 1 }}>
        {description}
      </Typography>
      <img src={image} alt={title} style={{ width: '100%' }} />
    </Paper>
  </Grid>;
};

export default ExplainerCard;